import React, {Component} from "react"
import { Link } from "gatsby"
class Navigation extends Component {
    render() {
        return (
            <div>
                <Link to={'/'}>Home</Link> {` `} | {` `}
                <Link to={'/hope'}>Hope</Link> {` `} | {` `}
                <Link to={'/about'}>About</Link> {` `} | {` `}
                <Link to={'/mybooks'}>My Books</Link> {` `} | {` `}
                <Link to={'/news'}>News & Press</Link> {` `} | {` `}
                <Link to={'/seminars'}>Seminars</Link>    {` `}  
            </div>
        );
    }
}

export default Navigation;